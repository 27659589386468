import { createTheme  } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#1A1F27',
    },
    background: {
      default: '#232629',
      paper: '#000000',
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255, 255, 255, 0.5)',
      h4: 'rgba(255, 255, 255, 0.8)',
      disabled: '#9CA5B4',
    },
  },

  typography:{
    fontFamily: [
      'sans-serif',
      'Helvetica Neue',
      'Helvetica',
      'Roboto',
      'SF Pro Icons',
      'SF Pro Display',
      'Arial',
    ],

    h1: {
      fontSize: '10em',
      fontWeight:'800',
    },
    h2: {
        fontSize: '5em',
        fontWeight:'200',
      },
    h3: {
      fontSize: '2em',
      fontWeight:'200',
    },
    h4: {
      fontSize: '1.2em',
      fontWeight:'200',
    },
    button:{
      fontSize: '1.4em',
      fontWeight:'400',
      whiteSpace: 'nowrap',
    },
    button2:{
      fontSize: '1vw',
      fontWeight:'400',
      whiteSpace: 'nowrap',
    },
    body2:{
      fontSize: '1em',
      fontWeight:'400',
      textAlign: 'justify',
    },
    p:{
        fontSize: '1em',
        fontWeight:'200',
        textAlign: 'justify',
        lineHeight:'150%',
      },
  },


  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1536,
    },
  },

});

theme.typography.h1[theme.breakpoints.down('sm')] = {
  fontSize: '4em',
};

theme.typography.h1[theme.breakpoints.up('xl')] = {
  fontSize: '20em',
};

theme.typography.h2[theme.breakpoints.down('sm')] = {
    fontSize: '2em',
};

theme.typography.h3[theme.breakpoints.down('sm')] = {
    fontSize: '1.4em',
};

theme.typography.h4[theme.breakpoints.down('sm')] = {
  fontSize: '1em',
};

theme.typography.p[theme.breakpoints.down('sm')] = {
    fontSize: '0.8em',
};

theme.typography.button[theme.breakpoints.down('sm')] = {
  fontSize: '1em',
};

theme.typography.button2[theme.breakpoints.down('sm')] = {
  fontSize: '3vh',
};

theme.typography.body2[theme.breakpoints.down('sm')] = {
  fontSize: '0.1em',
};

export default theme;


