import logo from './img/Group.svg';
import { ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import theme from './theme'

import fiberPrinter from './img/fiber_newimg.png'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'

function App() {

  return (
  <ThemeProvider theme={theme}>


    <Box
      sx={{
        position:'fixed',
        height:'100vh',
        width:'100vw',
        background:'linear-gradient(180deg,#000000 0%, #666666 100%)',

          padding:{
              xs:'5%',
              sm:'2%'
          },
          boxSizing:'border-box'
      }}
    >

        <Avatar alt="Remy Sharp" src={fiberPrinter} sx={{
          width:'auto',
          height:{
              xs:'60vh',
              sm:'100vh'
          },
          position:'absolute',
          right: '-5vw',
            top:{
              xs:'20vh',
              sm:'3vh'
            },
          zIndex:-1
      }} variant="square" />

      <Box sx={{
          display:'flex',
          flexDirection:'column',
          justifyContent:'space-between',
          color:'#FFFFFF',
          height:'100%'
      }}>

          <Box sx={{
              display:'flex',
              alignItems:'center',
              justifyContent:'space-between'
          }}>
              <Avatar src={logo} sx={{
                  width:'100px',
                  height:'44px'
              }} variant="square"/>

              <Typography
                sx={{
                    height: '31px',

                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: {
                        xs:'18px',
                        sm:'25px'
                    },
                    lineHeight:'31px',
                    textAlign:'right',
                    color:'#FFFFFF'


                }}
              >
                  Fiber Additive, Smart Manufacturing
              </Typography>
          </Box>


            <Box sx={{
                marginBottom:'50px',
                width:{
                    'xs':'90vw',
                    'sm':'90vw',
                    'md':'80vw',
                    'lg':'60vw',
                    'xl':'54vw'
                },
                height:'190px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'space-between'
            }}>


                <Typography sx={{
                    width: {
                        'xs':'90vw',
                        'sm':'54vw'
                    },
                    height: '38px',
                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: {
                        xs:'20px',
                        sm:'30px'
                    },
                    lineHeight:'38px',
                    textAlign:{
                        xs:'center',
                        sm:'left'
                    }
                }}>
                    Alpha 450 & PicaNest 120
                </Typography>
                <Typography sx={{
                    /* Half-Meter Continuous Fiber High-Speed 3D Printing Equipment */

                    width:  {
                        'xs':'90vw',
                        'sm':'90vw',
                        'md':'80vw',
                        'lg':'60vw',
                        'xl':'54vw'
                    },
                    height: '112px',
                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: {
                        'xs':'30px',
                        'sm':'45px',
                        'md':'50px',
                        'lg':'60px',
                        'xl':'60px'
                    },
                    lineHeight: {
                        'xs':'40px',
                        'sm':'55px',
                        'md':'60px',
                        'lg':'65px',
                        'xl':'70px'
                    },
                    textAlign:{
                        'xs':'center',
                        'sm':'left'
                    }

                }}>
                    Half-Meter Continuous Fiber High-Speed 3D<br /> Printing Equipment
                </Typography>
            </Box>
            <Box sx={{
                width:'100vw',
                height:{
                    'xs':'80px',
                    'sm':'68px'
                },
                display:'flex',
                flexDirection:'column',
                justifyContent:'space-between'
            }}>
                <Typography sx={{
                    width: '90vw',
                    height: '20px',
                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: {
                        'xs':'14px',
                        'sm':'16px'
                    },
                    lineHeight: {
                        'xs':'18px',
                        'sm':'20px'
                    }

                }}>
                    "The official brand website is under construction. Stay tuned!"
                </Typography>
                <Typography sx={{
                    width: '100vw',
                    height: '36px',
                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: {
                        'xs':'12px',
                        'sm':'14px'
                    },
                    lineHeight: {
                        'xs':'16px',
                        'sm':'18px'
                    }
                }}>
                    E-mail：sales@cfsys.com<br />
                    Phone：+86 183-0511-2162
                </Typography>
            </Box>

      </Box>


    </Box>
  </ThemeProvider>
  );
}

export default App;
