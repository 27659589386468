import React, { useRef,createContext, useState, useContext } from 'react';
import { useMediaQuery } from '@mui/material'
import theme from './theme'
import gsap from 'gsap'
import { useGSAP } from "@gsap/react";

// 创建上下文对象（使用 PascalCase 命名约定）
const MyContext = createContext();

// 上下文 Provider 组件
export const MyContextProvider = ({ children }) => {
   // 设置初始加载状态为true
   const [isLoading, setIsLoading] = useState(true);
   // 设置加载进度的状态
   const [progress, setProgress] = useState(0);
   const [buttonShow, setButtonShow] = useState(false);

   const [isMouseDown, setMouseDown] = useState(0);//设置滑块按钮的按下和松开
   const [MouseDownOk, setMouseDownOk] = useState(false);//设置是否可以按下
   const [MoveCompleted, setMoveCompleted] = useState(false);//设置是否可以按下

  const contextValues = {
    state:{
      isLoading,
      progress,
      buttonShow,
      isMouseDown,
      MouseDownOk,
      MoveCompleted,
    },
    set:{
      setIsLoading,
      setProgress,
      setButtonShow,
      setMouseDown,
      setMouseDownOk,
      setMoveCompleted,
    }
  };


  return (
    <MyContext.Provider value={contextValues}>
      {children}
    </MyContext.Provider>
  );
};

// 自定义 hook，用于访问 Context 中的状态
export const useMyContext = () => useContext(MyContext);

